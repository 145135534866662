import type { GetServerSideProps } from "next";
import {
  getFiltersFromQuery,
  toStrippedArray
} from "../../api-utils/item-filters";
import ContentGridPage, { Props } from "../content-grid";
import { ensureAllowedSort } from "../../components/search/shared";
import createServerContext from "../../context/server-context";
import { countItems, findItems, getItemFacets } from "../../data/db";
import { setCacheHeader } from "../../page-cache";

export default ContentGridPage;

export const getServerSideProps: GetServerSideProps<Props> = async (
  baseCtx
) => {
  const ctx = createServerContext(baseCtx);
  const filters = getFiltersFromQuery(baseCtx.query);
  const sort = ensureAllowedSort(toStrippedArray(baseCtx.query.sort)[0]);
  const [items, facets, { totalItems, pages }] = await Promise.all([
    findItems(ctx, filters, sort),
    getItemFacets(ctx),
    countItems(ctx, filters)
  ]);

  setCacheHeader(ctx, baseCtx.res);

  return {
    props: {
      data: {
        items,
        totalItems,
        pages
      },
      filters,
      facets
    }
  };
};
